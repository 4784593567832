import type { Action } from "svelte/action";

type Options = {
  callback?: (entry: IntersectionObserverEntry) => void;
  disabled?: boolean;
  unobserveOnEnter?: boolean;
} & IntersectionObserverInit;

/**
 * Observes when an element enters or exits the viewport.
 * It uses the IntersectionObserver API to trigger a callback when the element's visibility changes.
 *
 * @param node - The HTML element to be observed.
 * @param opts - Options for configuring the IntersectionObserver and the callback function.
 * @returns An object with a destroy method to unobserve the element.
 */
export const inview: Action<HTMLElement, Options> = (node, opts = {}) => {
  function createObserver(config: Options) {
    if (opts.disabled) {
      return;
    }

    return new IntersectionObserver(
      (entries, _observer) =>
        entries.forEach((entry) => {
          config.callback?.(entry);
          if (entry.isIntersecting && config.unobserveOnEnter) {
            _observer.unobserve(node);
          }
        }),
      {
        root: config.root,
        rootMargin: config.rootMargin,
        threshold: config.threshold,
      },
    );
  }

  let observer = createObserver(opts);
  observer?.observe(node);

  return {
    update(newOpts) {
      observer?.disconnect();
      observer = createObserver(newOpts);
      observer?.observe(node);
    },
    destroy() {
      observer?.disconnect();
    },
  };
};
