import * as basicscroll from "basicscroll";
import type { Action } from "svelte/action";

type ParallaxConfig = {
  props: basicscroll.Data["props"];
  from?: basicscroll.Data["from"];
  to?: basicscroll.Data["to"];
};

/**
 * Creates a parallax effect on a given element using basicScroll.
 * It applies a vertical translation based on scroll position within specified anchors.
 *
 * @param node The HTML element to apply the parallax effect to.
 * @param config Configuration for the parallax effect, including from, to, and optional anchor points.
 * @returns An object with destroy and update methods to manage the parallax effect lifecycle.
 */
export const parallax: Action<HTMLElement, ParallaxConfig> = (
  node,
  { props, from = "top-bottom", to = "bottom-top" },
) => {
  const parallax = basicscroll.create({
    elem: node,
    direct: true,
    from,
    to,
    props,
  });
  parallax.start();

  return {
    destroy() {
      parallax.destroy();
    },
    update() {
      parallax.update();
      parallax.start();
    },
  };
};
