<!--
### Custom graphic for home page feature tour
 -->
<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import GalleryGraphic from "./graphics/GalleryGraphic.svelte";
  import DesignGraphic from "./graphics/DesignGraphic.svelte";

  const GRAPHICS = {
    gallery: GalleryGraphic,
    design: DesignGraphic,
  };

  type FeatureGraphicProps = {
    /** Key of the custom graphic */
    graphic: keyof typeof GRAPHICS;
  } & HTMLAttributes<HTMLDivElement>;

  const {
    graphic,
    class: className = "",
    ...props
  }: FeatureGraphicProps = $props();
  const Component = $derived(GRAPHICS[graphic]);
</script>

<style>
  .graphic {
    width: 100%;
    aspect-ratio: 4/3;
  }
</style>

<div class="graphic {className}" {...props}>
  <Component />
</div>
