<!--
### Custom range input 
 -->
<script lang="ts">
  import { clamp } from "lodash-es";
  import type { HTMLAttributes } from "svelte/elements";

  type RangeProps = {
    /** Current value of the range, 0 - 1 */
    value: number;
  } & HTMLAttributes<HTMLDivElement>;

  let {
    value = $bindable(),
    class: className = "",
    ...props
  }: RangeProps = $props();

  let isDragging = $state();
  let track = $state<HTMLDivElement>();

  function isTouch(event: MouseEvent | TouchEvent): event is TouchEvent {
    return "touches" in event;
  }

  function updateValue(e: MouseEvent | TouchEvent) {
    if (!track) {
      return;
    }

    const clientX = isTouch(e) ? e.changedTouches[0].clientX : e.clientX;
    const trackX = track.getBoundingClientRect().left;
    const offset = clientX - trackX;

    value = clamp(offset / track.clientWidth, 0, 1);
  }

  function startDrag(e: MouseEvent | TouchEvent) {
    updateValue(e);
    isDragging = true;
  }

  function drag(e: MouseEvent | TouchEvent) {
    if (isDragging) {
      updateValue(e);
    }
  }

  function endDrag() {
    isDragging = false;
  }
</script>

<style>
  .track {
    position: relative;
    height: var(--range-track-size, var(--size-2));
    width: 100%;
    border-radius: var(--radius-full);
    background: var(--range-track-background, var(--color-bg-secondary));
    cursor: pointer;
  }

  .thumb {
    position: absolute;
    height: var(--range-thumb-size, var(--size-4));
    width: var(--range-thumb-size, var(--size-4));
    background: var(--range-thumb-background, var(--color-accent));
    border: var(--range-thumb-border, none);
    border-radius: var(--radius-100);
    cursor: pointer;
    transform: translate(-50%, -50%);
    top: 50%;
  }
</style>

<svelte:window
  onmousemove={drag}
  ontouchmove={drag}
  onmouseup={endDrag}
  ontouchend={endDrag}
/>

<div
  bind:this={track}
  class="track {className}"
  role="slider"
  tabindex={-1}
  aria-valuenow={value}
  onmousedown={startDrag}
  ontouchstart={startDrag}
  {...props}
>
  <div class="thumb" style:left="{value * 100}%"></div>
</div>
