<script lang="ts">
  import ImageUploading from "@hyperfocal/design/components/ImageUploading/ImageUploading.svelte";
  import Input from "@hyperfocal/design/components/Input/Input.svelte";
  import Textarea from "@hyperfocal/design/components/Textarea/Textarea.svelte";
  import DataList from "@hyperfocal/design/components/DataList/DataList.svelte";
  import { format } from "date-fns";
  import { inview, parallax } from "@hyperfocal/actions";
  import { page } from "$app/stores";
  import { src } from "$src/lib/sanity";
  import { type SanityImageAsset } from "$generated/sanity";

  const ROWS = 2;
  const PLACEHOLDERS = ROWS * 2;

  const photos = $derived($page.data.global.photos.slice(0, PLACEHOLDERS));
  const photoUrls = $derived(
    photos.map(
      (photo) =>
        `${src((photo.asset as unknown as SanityImageAsset)?.url)}&fit=max&w=1024`,
    ),
  );

  let viewportHeight = $state<number>(0);
  let container = $state<HTMLDivElement>();
  let isInView = $state<boolean>(false);
  let progress = $state<number>(0);

  function scrollProgress() {
    if (container && viewportHeight) {
      progress = isInView
        ? 1 - container.getBoundingClientRect().top / viewportHeight
        : 0;
    }
  }
</script>

<style>
  .graphic {
    --color-text: white;
    --color-text-secondary: var(--color-grey-400);
    --color-text-tertiary: var(--color-grey-500);
    --color-bg: var(--color-black);
    --color-bg-secondary: var(--color-grey-700);
    --color-bg-tertiary: var(--color-grey-900);
    --color-border: var(--color-grey-700);
    --color-border-secondary: var(--color-grey-800);
    --color-border-tertiary: var(--color-grey-900);
    --color-black-opacity: rgba(16, 24, 28, 0.7);
    --color-black-opacity-900: var(--color-black-opacity);
    --color-grey-opacity: rgba(85, 93, 110, 0.1);
    display: grid;
    grid-template-columns: var(--grid-2);
    grid-template-rows: repeat(auto, 2);
    grid-auto-flow: dense;
    width: 100%;
    height: 100%;
    gap: var(--size-2);
    @media (--2xl) {
      grid-template-columns: minmax(auto, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      gap: var(--size-4);
    }
  }

  .sidebar-panel {
    display: none;
    @media (--2xl) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sidebar {
    transform: translateY(var(--sidebar-y));
    display: flex;
    flex-direction: column;
    gap: var(--size-6);
    width: 100%;
    max-width: var(--size-80);
    min-width: var(--size-72);
    padding: var(--size-6);
    border-radius: var(--radius-lg);
    background: var(--color-black);
  }

  .image {
    display: flex;
    align-items: center;
    justify-self: center;
    grid-column: span 1;
    max-width: 100%;
  }

  .image-inner {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    @media (--xl) {
      aspect-ratio: 4/3;
    }
  }
</style>

<svelte:window onscroll={scrollProgress} bind:innerHeight={viewportHeight} />

<svelte:head>
  {#each photoUrls as photoUrl}
    <link rel="preload" href={photoUrl} as="image" />
  {/each}
</svelte:head>

<div
  class="graphic"
  bind:this={container}
  use:inview={{ callback: ({ isIntersecting }) => (isInView = isIntersecting) }}
>
  <div class="sidebar-panel" style:grid-row="1 / {ROWS + 1}">
    <div
      class="sidebar"
      use:parallax={{
        props: {
          "--sidebar-y": {
            from: "100px",
            to: "-100px",
          },
        },
      }}
    >
      <Input label="Title" placeholder="Give your photo a title" />
      <Textarea
        label="Description"
        placeholder="Your photo's artist statement"
        rows={3}
      />

      <DataList
        label="Metadata"
        data={[
          {
            label: "Resolution",
            value: "5746 x 3831",
          },
          {
            label: "Captured",
            value: format(new Date(), "dd MMM, yyyy"),
          },
          {
            label: "Focal Length",
            value: "50mm",
          },
          {
            label: "Exposure Setings",
            value: "1/500s at f/8",
          },
        ]}
      />
    </div>
  </div>
  {#each Array.from({ length: PLACEHOLDERS }) as _, i}
    {@const imageProgress = progress * (PLACEHOLDERS / (i + 1))}
    <div class="image">
      <div class="image-inner">
        <ImageUploading
          preview={imageProgress >= 1 ? photoUrls[i] : undefined}
          progress={imageProgress}
        />
      </div>
    </div>
  {/each}
</div>
