<!--
### UI Color picker
 -->
<script lang="ts">
  import { colord } from "colord";
  import type { HTMLAttributes } from "svelte/elements";
  import Input from "../Input/Input.svelte";
  import Range from "../Range/Range.svelte";
  import { clamp } from "lodash-es";
  import { untrack } from "svelte";

  type ColorPickerProps = {
    /** Hex value of the picker */
    value: string;
  } & HTMLAttributes<HTMLDivElement>;

  let {
    value = $bindable(),
    class: className = "",
    ...props
  }: ColorPickerProps = $props();

  let huePercentage = $state<number>(0);
  let saturation = $state<number>(100);
  let vibrance = $state<number>(100);
  let canvas = $state<HTMLDivElement>();
  let canvasDragging = $state();

  const hue = $derived(Math.round(360 * huePercentage));
  const hueColor = $derived(colord({ h: hue, s: 100, v: 100, a: 1 }).toHex());

  function isTouch(event: MouseEvent | TouchEvent): event is TouchEvent {
    return "touches" in event;
  }

  function updateValue(e: MouseEvent | TouchEvent) {
    if (!canvas) {
      return;
    }

    const { left, top, width, height } = canvas.getBoundingClientRect();
    const target = isTouch(e) ? e.changedTouches[0] : e;
    const mouse = {
      x: clamp(target.clientX - left, 0, width),
      y: clamp(target.clientY - top, 0, height),
    };

    saturation = clamp(mouse.x / width, 0, 1) * 100;

    vibrance = clamp((height - mouse.y) / height, 0, 1) * 100;
  }

  function startDrag(e: MouseEvent | TouchEvent) {
    updateValue(e);
    canvasDragging = true;
  }

  function drag(e: MouseEvent | TouchEvent) {
    if (canvasDragging) {
      updateValue(e);
    }
  }

  function endDrag() {
    canvasDragging = false;
  }

  $effect(() => {
    untrack(() => {
      const { h, s, v } = colord(value).toHsv();
      huePercentage = Number((h / 360).toFixed(2));
      saturation = s;
      vibrance = v;
    });
  });

  $effect(() => {
    value = colord({ h: hue, s: saturation, v: vibrance, a: 1 }).toHex();
  });
</script>

<style>
  .picker {
    --picker-thumb-size: var(--size-6);
    --picker-thumb-border: 3px solid white;
    display: flex;
    flex-direction: column;
    gap: var(--size-3);
  }

  .canvas {
    position: relative;
    aspect-ratio: 3/2;
    border-radius: var(--radius-lg);
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--color-border-secondary);
  }

  .canvas-thumb {
    position: absolute;
    width: var(--picker-thumb-size);
    height: var(--picker-thumb-size);
    border: var(--picker-thumb-border);
    border-radius: var(--radius-full);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .hue {
    --saturation: 85%; /* standard = 100% */
    --luminance: 55%; /* standard = 50% */
    --range-track-size: var(--size-3);
    --range-thumb-size: var(--picker-thumb-size);
    --range-thumb-border: var(--picker-thumb-border);
    --range-track-background: linear-gradient(
      to right in hsl,
      hsl(0, var(--saturation), var(--luminance)) 0%,
      hsl(60, var(--saturation), var(--luminance)) 17%,
      hsl(120, var(--saturation), var(--luminance)) 33%,
      hsl(180, var(--saturation), var(--luminance)) 50%,
      hsl(240, var(--saturation), var(--luminance)) 67%,
      hsl(300, var(--saturation), var(--luminance)) 83%,
      hsl(0, var(--saturation), var(--luminance)) 100%
    );
  }

  .value {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-border-secondary);
    color: var(--color-text-secondary);
    border-radius: var(--radius-sm);
    gap: var(--size-2);
    & :global(input) {
      padding-left: 0;
    }
  }

  .value-swatch {
    width: var(--size-6);
    height: var(--size-6);
    border-radius: var(--radius-sm);
    border: 1px solid var(--color-border-tertiary);
    margin-left: var(--size-2);
  }
</style>

<svelte:window
  onmousemove={drag}
  ontouchmove={drag}
  onmouseup={endDrag}
  ontouchend={endDrag}
/>

<div class="picker {className}" {...props}>
  <div
    bind:this={canvas}
    class="canvas"
    role="slider"
    tabindex={-1}
    aria-valuenow={saturation}
    style:background="linear-gradient(#ffffff00, #000000ff),
    linear-gradient(0.25turn, #ffffffff, #00000000), {hueColor}"
    onmousedown={startDrag}
    ontouchstart={startDrag}
  >
    <div
      class="canvas-thumb"
      style:background={value}
      style:top="{100 - vibrance}%"
      style:left="{saturation}%"
    ></div>
  </div>
  <div class="hue" style:--range-thumb-background={hueColor}>
    <Range bind:value={huePercentage} />
  </div>
  <div class="value">
    <div class="value-swatch" style:background={value}></div>
    <Input bind:value minimal />
  </div>
</div>
