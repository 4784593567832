<!--
### Image upload progress placeholder
 -->
<script lang="ts">
  import { clamp } from "lodash-es";
  import type { HTMLAttributes } from "svelte/elements";
  import { fade } from "svelte/transition";

  const FADE_DURATION = 200;

  type ImageUploadingProps = {
    /** Progress of the upload */
    progress: number;
    /** Optional thumbnail to replace loading bar */
    preview?: string;
  } & HTMLAttributes<HTMLDivElement>;

  const {
    progress,
    preview,
    class: className = "",
    ...props
  }: ImageUploadingProps = $props();

  const percentage = $derived(clamp(progress, 0, 1));
  const complete = $derived(percentage === 1);
</script>

<style>
  .image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-tertiary);
    border-radius: var(--radius-lg);
    overflow: hidden;
  }

  .preview {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &:not(.complete) {
      filter: blur(2px);
    }
  }

  .track {
    position: relative;
    width: 80%;
    max-width: var(--width-xs);
    height: var(--size-2);
    background: var(--color-bg);
    border-radius: var(--radius-full);
    overflow: hidden;
  }

  .bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    background: var(--color-accent-700);
    border-radius: var(--radius-full);
    transition: transform 150ms ease;
  }
</style>

<div class="image {className}" data-testid="image-uploading" {...props}>
  {#if preview}
    <img
      data-testid="image-uploading-preview"
      transition:fade={{ duration: FADE_DURATION }}
      class="preview"
      class:complete
      src={preview}
      alt="Preview"
    />
  {/if}
  {#if !preview || !complete}
    <div class="track" transition:fade={{ duration: FADE_DURATION }}>
      <div
        class="bar"
        style:transform="scaleX({percentage})"
        data-testid="image-uploading-loader"
      ></div>
    </div>
  {/if}
</div>
