<!--
### Data list table
-->
<script lang="ts">
  import { interact } from "@hyperfocal/actions";
  import arrowIcon from "lucide-static/icons/chevron-down.svg?raw";
  import { slide } from "svelte/transition";

  type DataListProps = {
    /** Label for the list */
    label: string;
    /** List of data */
    data: { label: string; value: string }[];
    /** Whether it's collapsed */
    collapsed?: boolean;
  };

  let { label, data = [], collapsed = $bindable() }: DataListProps = $props();
</script>

<style>
  .wrapper {
    font: var(--text-sm);
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text-secondary);
    font-weight: var(--weight-semibold);
    cursor: pointer;
    transition: color 150ms var(--ease-standard);
    &:hover {
      color: var(--color-text);
    }
  }

  .label-icon {
    transition: transform 200ms var(--ease-standard);
    transform: rotate(180deg);
    &.collapsed {
      transform: rotate(0deg);
    }
    & :global(svg) {
      width: var(--size-4);
      height: var(--size-4);
      stroke: currentColor;
    }
  }

  .list {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    margin-top: var(--size-1);
  }

  .cell {
    padding: var(--size-2) 0;
    color: var(--color-text-secondary);
    border-bottom: 1px solid var(--color-border-secondary);
    &.value {
      color: var(--color-text);
      text-align: right;
    }
    &:nth-last-child(-n + 2) {
      border-bottom: none;
    }
  }
</style>

<div class="wrapper">
  <div class="label" use:interact={() => (collapsed = !collapsed)}>
    <span>{label}</span>
    <span class="label-icon" class:collapsed>{@html arrowIcon}</span>
  </div>
  {#if !collapsed}
    <div class="list" transition:slide|local>
      {#each data as item}
        {#if item}
          <div class="cell">
            {item?.label}
          </div>
          <div class="cell value">
            {item?.value}
          </div>
        {/if}
      {/each}
    </div>
  {/if}
</div>
