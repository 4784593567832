<script lang="ts">
  import checkIcon from "lucide-static/icons/check.svg?raw";
  import type SvelteSelect from "svelte-select";

  type SelectItemProps = {
    // The item to be selected
    item: SvelteSelect["Item"];
    // Whether the item is compact
    compact?: boolean;
    // Whether the item is active
    active?: boolean;
  };

  const { item, compact = false, active = false }: SelectItemProps = $props();
</script>

<style>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 100%;
    color: var(--color-text);
  }

  .item-content {
    display: flex;
    align-items: center;
  }

  .selected :global(svg) {
    width: var(--size-4);
    height: var(--size-4);
    stroke: var(--color-text);
  }

  .icon {
    max-height: var(--size-6);
    overflow: hidden;
    &.withLabel {
      margin-right: var(--size-2);
    }
  }
</style>

<div class="item" class:active data-testid="select-item">
  <div class="item-content">
    {#if item.icon}
      {@const Icon = item.icon}
      <div
        class="icon"
        data-testid="select-item-icon"
        class:withLabel={!!item.label}
      >
        <Icon {...item} />
      </div>
    {/if}
    {#if !compact}
      {item.label}
    {/if}
  </div>
  {#if active}
    <span class="selected" data-testid="select-item-active">
      {@html checkIcon}
    </span>
  {/if}
</div>
