<script lang="ts">
  import { type SanityImageAsset } from "$generated/sanity";
  import Card from "$src/components/Card/Card.svelte";
  import FeatureGraphic from "$src/components/FeatureGraphic/FeatureGraphic.svelte";
  import Meta from "$src/components/Meta.svelte";
  import Richtext from "$src/components/Richtext/Richtext.svelte";
  import { imgProps } from "$src/lib/sanity";
  import { parallax } from "@hyperfocal/actions";
  import { media } from "@hyperfocal/design/styles/media";
  import { random } from "lodash-es";

  const { data } = $props();

  const heroPhotoBounds: { width: [number, number]; height: [number, number] } =
    $derived.by(() => {
      let width: [number, number];
      let height: [number, number];

      if ($media?.["3xl"]) {
        width = [5, 15];
        height = [15, 25];
      }

      if ($media?.["2xl"]) {
        width = [10, 20];
        height = [15, 20];
      }

      if ($media?.xl) {
        width = [15, 30];
        height = [25, 35];
      }

      if ($media?.lg) {
        width = [20, 35];
        height = [25, 40];
      }

      if ($media?.md) {
        width = [25, 40];
        height = [20, 30];
      }

      width = [20, 35];
      height = [30, 45];

      return $media?.portrait
        ? { width: height, height: width }
        : { width, height };
    });
</script>

<style>
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .hero {
    position: relative;
    overflow: hidden;
    grid-column: 1 / -1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.8;
      z-index: var(--layer-1);
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - var(--app-header-height));
    min-height: var(100svh - var(--app-header-height));
    z-index: var(--layer-2);
    padding-top: 15vh;
    @media (--sm) {
      padding-top: 0;
      justify-content: center;
    }
  }

  .hero-title {
    font: var(--display-xl);
    letter-spacing: var(--letter-sm);
    font-weight: var(--weight-bold);
    text-align: center;
    z-index: var(--layer-1);
    pointer-events: none;
    @media (--sm) {
      margin-bottom: 15vh;
    }
    @media (--md) {
      font: var(--display-2xl);
      letter-spacing: var(--letter-sm);
    }
    @media (--xl) {
      font: var(--display-3xl);
      letter-spacing: var(--letter-sm);
      max-width: 18ch;
    }
  }

  .hero-photos {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    top: 0;
    left: -20%;
    width: 140%;
    height: 100%;
    gap: var(--size-8);
    margin-top: var(--size-12);
    @media (--md) {
      gap: var(--size-12);
    }
    @media (--lg) {
      gap: var(--size-16);
    }
    @media (--2xl) {
      gap: var(--size-20);
    }
  }

  .hero-photo {
    transform-origin: center;
    object-fit: contain;
    opacity: 0;
    animation: fadeIn forwards 800ms linear;
  }

  .hero-photo-image {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  .overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--size-12);
    @media (--md) {
      margin-top: var(--size-20);
    }
    @media (--lg) {
      margin-top: var(--size-24);
    }
  }

  .overview-title,
  .overview-copy {
    max-width: var(--width-sm);
    text-align: center;
  }

  .overview-title {
    font: var(--display-md);
    letter-spacing: var(--letter-sm);
    @media (--md) {
      font: var(--display-lg);
      letter-spacing: var(--letter-sm);
    }
  }

  .overview-copy {
    font: var(--text-lg);
    margin-top: var(--size-4);
    color: var(--color-text-secondary);
    @media (--md) {
      font: var(--text-xl);
    }
  }

  .tour {
    --tour-spacing: var(--size-20);
    --overflow-height: var(--size-32);
    margin-top: var(--tour-spacing);
    position: relative;
    grid-column: 1 / -1;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: var(--overflow-height);
      background: var(--color-accent-50);
    }
    @media (--md) {
      --overflow-height: var(--size-20);
      --tour-spacing: var(--size-28);
    }
    @media (--xl) {
      --tour-spacing: var(--size-32);
    }
    @media (--2xl) {
      --overflow-height: var(--size-36);
    }
  }

  .tour-inner {
    display: flex;
    flex-direction: column;
    gap: var(--tour-spacing);
  }

  .tour-panel {
    align-items: center;
    grid-auto-flow: dense;
    z-index: var(--layer-1);
  }

  .tour-panel-content,
  .tour-panel-graphic {
    grid-column: 1 / -1;
  }

  @media (--lg) {
    .tour-panel:nth-child(odd) {
      & .tour-panel-content {
        grid-column: 1 / span 5;
      }
      & .tour-panel-graphic {
        grid-column: 7 / -1;
      }
    }

    .tour-panel:nth-child(even) {
      & .tour-panel-content {
        grid-column: 8 / -1;
      }
      & .tour-panel-graphic {
        grid-column: 1 / span 6;
        justify-content: flex-end;
      }
    }
  }

  @media (--2xl) {
    .tour-panel:nth-child(odd) {
      & .tour-panel-content {
        grid-column: 1 / span 4;
      }
      & .tour-panel-graphic {
        grid-column: 6 / -1;
      }
    }

    .tour-panel:nth-child(even) {
      & .tour-panel-content {
        grid-column: 9 / span 4;
      }
      & .tour-panel-graphic {
        grid-column: 1 / span 7;
        justify-content: flex-end;
      }
    }
  }

  .tour-panel-title {
    font: var(--display-sm);
    letter-spacing: var(--letter-sm);
    @media (--sm) {
      font: var(--display-md);
      letter-spacing: var(--letter-sm);
    }
  }

  .tour-panel-copy {
    font: var(--text-md);
    margin-top: var(--size-4);
    max-width: var(--prose-xs);
    @media (--sm) {
      font: var(--text-lg);
    }
  }

  .tour-panel-graphic {
    display: flex;
    overflow: visible;
    margin-top: var(--size-10);
    @media (--lg) {
      margin-top: 0;
    }
  }

  .tour-panel-graphic > :global(.graphic) {
    flex-shrink: 0;
    width: 100%;
    @media (--lg) {
      width: 150%;
    }
    @media (--2xl) {
      width: 130%;
    }
  }

  .features {
    grid-column: 1 / -1;
    background: var(--color-accent-50);
    padding: var(--size-16) 0;
    @media (--md) {
      padding: var(--size-28) 0;
    }
    @media (--2xl) {
      padding: var(--size-40) 0;
    }
  }

  .features-list {
    display: flex;
    gap: var(--size-4);
    padding: 0 var(--grid-page-gutter);
    @media (--lg) {
      gap: var(--size-10);
    }
  }

  .feature {
    min-width: 75vw;
    color: var(--color-accent-900);
    & :global(.feature-card) {
      height: 100%;
    }
    @media (--md) {
      min-width: var(--size-96);
    }
    @media (--xl) {
      min-width: var(--width-xs);
    }
  }

  .feature-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (--md) {
      padding: var(--size-3);
      aspect-ratio: 4/3;
    }
  }

  .feature-icon {
    width: var(--size-9);
    height: var(--size-9);
    object-fit: contain;
    object-position: top left;
    @media (--md) {
      width: var(--size-10);
      height: var(--size-10);
    }
  }

  .feature-title {
    font: var(--display-sm);
    font-weight: var(--weight-medium);
    margin-top: var(--size-8);
    @media (--lg) {
      margin-top: var(--size-12);
    }
  }

  .feature-copy {
    font: var(--text-md);
    color: var(--color-accent-900);
    margin-top: var(--size-3);
    opacity: 0.75;
    @media (--md) {
      font: var(--text-lg);
    }
  }
</style>

<Meta
  title={data.meta?.title}
  description={data.meta?.description}
  image={data.meta?.image?.asset as unknown as SanityImageAsset}
/>

<section class="hero">
  <div class="--grid-page">
    <div class="hero-content">
      <h1 class="hero-title">{data.title}</h1>
    </div>
  </div>
  <div class="hero-photos">
    {#each data.heroPhotos.photos as heroPhoto}
      {@const width = Math.round(random(...heroPhotoBounds.width))}
      {@const height = Math.round(random(...heroPhotoBounds.height))}
      <div
        class="hero-photo"
        style:animation-delay="{Math.round(random(250, 750))}ms"
        style:transform="translate({random(-10, 10)}%, {random(-20, 20)}%)"
        use:parallax={{
          props: {
            [`--${heroPhoto._key}`]: {
              from: `${random(-25, -10)}%`,
              to: `${random(15, 25)}%`,
            },
          },
        }}
      >
        <img
          {...imgProps(heroPhoto.asset as unknown as SanityImageAsset)}
          class="hero-photo-image"
          style:transform="translateY(var(--{heroPhoto._key}))"
          style:max-width="{width}vw"
          style:max-height="{height}vh"
          sizes="{width}vw"
        />
      </div>
    {/each}
  </div>
</section>

<section class="overview">
  <h2 class="overview-title">{data.overview.title}</h2>
  <div class="overview-copy --richtext">
    <Richtext value={data.overview.copy} />
  </div>
</section>

<section class="tour --grid-page">
  <div class="tour-inner">
    {#each data.tour as { title, copy, graphic }}
      <div class="tour-panel --grid-content">
        <div class="tour-panel-content">
          <h2 class="tour-panel-title">{title}</h2>
          <div class="tour-panel-copy --richtext">
            <Richtext value={copy} />
          </div>
        </div>
        <div class="tour-panel-graphic">
          <FeatureGraphic
            class="graphic"
            graphic={graphic.current as "gallery" | "design"}
          />
        </div>
      </div>
    {/each}
  </div>
</section>

<section class="features" id="features">
  <div class="features-list --scrollable">
    {#each data.features as { title, copy, icon }}
      <div class="feature">
        <Card class="feature-card">
          <div class="feature-inner">
            <img
              class="feature-icon"
              {...imgProps(icon.asset as unknown as SanityImageAsset)}
            />
            <div>
              <h3 class="feature-title">{title}</h3>
              <div class="feature-copy --richtext">
                <Richtext value={copy} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    {/each}
  </div>
</section>
